import { ResponsiveForm, ThemeColor, ThemeShade } from '@papa/components';
import { StyledLink, StyledSeparator } from '../../styles/FormElements';
import styled from 'styled-components';
import { dropins } from '../../dataModel/dropins';

export const yesOrNoAnswers = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
];

interface IWrapperProps {
    children: JSX.Element | JSX.Element[];
}

export const CustomFormWrapper = (props: IWrapperProps) => {
    return (
        <>
            <ResponsiveForm.Root>{props.children}</ResponsiveForm.Root>
            <StyledSeparator orientation="horizontal" weight="thin" color={ThemeColor.neutral} shade={ThemeShade.light} />
        </>
    );
};

interface ISubtextProps {
    children: string | JSX.Element | JSX.Element[];
}
export const Subtext = styled.p`
    font-size: 0.9em;
    margin: 0;
    color: ${props => props.theme.secondary.light};
    font-family: ${props => props.theme.font.italic};
`;
export const ItalicSubtext = (props: ISubtextProps) => {
    return (
        <Subtext>
            <i>{props.children}</i>
        </Subtext>
    );
};

export const ErrorSubtext = styled.p`
    font-size: 0.9em;
    margin: 0;
    color: ${props => props.theme.error.dark};
    font-family: ${props => props.theme.font.italic};
`;

export const ErrorItalicSubtext = (props: ISubtextProps) => {
    return (
        <ErrorSubtext>
            <i>{props.children}</i>
        </ErrorSubtext>
    );
};

export const UppercaseSubheader = styled.h3`
    font-size: 1em;
    margin: 1.25rem 0 0.5rem 0;
    letter-spacing: 0.4rem;
    font-weight: 900;
    color: ${props => props.theme.secondary.dark};
    font-family: ${props => props.theme.font.display};
    text-transform: uppercase;
`;

export const PartnerMailToLink = <StyledLink href={`mailto:${dropins.partnerEmail}`}>{dropins.partnerEmail}</StyledLink>;
