import {
    ResponsiveLayout,
    Separator,
    StyledSection,
    HorizontalNav,
    HorizontalNavMenuItem,
    AddressBlock,
    CeebBlock,
    RadioInput,
    ThemeColor,
    ThemeShade,
    DependentSelect,
    ValidationErrorSummary,
    SharedErrorStyling,
    MaterialIcon,
} from '@papa/components';
import styled from 'styled-components';

export const StackedFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const StyledValidationErrorSummary = styled(ValidationErrorSummary.Root)`
    &${ValidationErrorSummary.RootContainer} {
        background-color: ${props => props.theme.error.light}40;
        box-shadow: ${props => props.theme.boxShadow.main};
        padding: 1rem 2rem 2rem 2rem;
        border-radius: ${props => props.theme.borderRadius};
    }
    &${SharedErrorStyling.Container} {
        color: ${props => props.theme.error.dark};
    }
` as typeof ValidationErrorSummary.Root;

export const BlockRadioInput = styled(RadioInput.Root)`
    &${RadioInput.RadioGroup} {
        outline: none;
        display: flex;
        flex-direction: column;
        align-items: unset;
        gap: 0.7rem;
    }
` as typeof RadioInput.Root;

export const StyledAddressBlock = styled(AddressBlock.Root)`
    &${AddressBlock.ElementContainer} {
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr 1.62fr;
        width: 100%;
        padding: 1rem 0;
        @media screen and (max-width: calc(${props => props.theme.layout.tablet} - 1px)) {
            display: block;
        }
    }
` as typeof AddressBlock.Root;

export const StyledCeebBlock = styled(CeebBlock.Root)`
    &${CeebBlock.ElementContainer} {
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr 1.62fr;
        width: 100%;
        padding: 1rem 0;
        @media screen and (max-width: calc(${props => props.theme.layout.tablet} - 1px)) {
            display: block;
        }
    }
` as typeof CeebBlock.Root;

export const StyledDependentSelect = styled(DependentSelect.Root)`
    &${DependentSelect.ElementContainer} {
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr 1.62fr;
        width: 100%;
        padding: 1rem 0;
        @media screen and (max-width: calc(${props => props.theme.layout.tablet} - 1px)) {
            display: block;
        }
    }
` as typeof DependentSelect.Root;

export const Blurb = styled.p<{ color: keyof typeof ThemeColor; shade: keyof typeof ThemeShade; fontSize?: string }>`
    margin: 0;
    font-family: ${props => props.theme.font.main};
    color: ${props => props.theme[props.color][props.shade]};
    line-height: 1.3;
    font-size: ${props => props.fontSize || '0.9em'};
`;
export const Flex = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;

export const StyledLink = styled.a`
    color: ${props => props.theme.primary.main};
    :visited: ${props => props.theme.primary.light};
`;

export const StyledSeparator = styled(Separator.Root)`
    margin: 1rem 0;
` as typeof Separator.Root;

export const lightSeparator = <StyledSeparator orientation={Separator.Orientations.horizontal} weight="thin" color={ThemeColor.neutral} shade={ThemeShade.light} />;

export const ErrorStyledSectionHeader = styled(StyledSection.Header)`
    font-family: ${props => props.theme.font.main};
    font-weight: 700;
    letter-spacing: 0.2rem;
    font-size: 2.2em;
    margin: 0.25rem;
    color: ${props => props.theme.error.dark};
`;

export const ErrorStyledIcon = styled(MaterialIcon.Root)`
    color: ${props => props.theme.error.dark};
    align-self: center;
    font-size: 2.2em;
`;

export const CustomSection = styled(StyledSection.Root)`
    &${StyledSection.Header} {
        font-family: ${props => props.theme.font.main};
        font-weight: 700;
        letter-spacing: 0.2rem;
        font-size: 2.2em;
        margin: 0;
        color: ${props => props.theme.secondary.light};
    }

    &${StyledSection.Container} {
        box-shadow: ${props => props.theme.boxShadow.main};
        padding: 1rem 2rem 2rem 2rem;
        background-color: ${props => props.theme.white};
        border-radius: ${props => props.theme.borderRadius};
    }

    &${Separator.Root} {
        margin: 2rem 0 0 0;
    }
` as typeof StyledSection.Root;

export const StyledHorizontalNav = styled(HorizontalNav.Root)`
    &${HorizontalNav.Container} {
        background-color: ${props => props.theme.secondary.main};
        display: flex;
        flex-direction: row;
    }
    &${HorizontalNav.Container} > div {
        display: flex;
        justify-content: center;
        width: 100vw;
    }
    &${HorizontalNav.MenuList} {
        width: ${props => props.theme.layout.tablet};
        color: ${props => props.theme.neutral.faint};
        margin: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
` as typeof HorizontalNav.Root;

export const StyledHorizontalNavMenuItem = styled(HorizontalNavMenuItem.Root)`
    padding: 1rem 0rem;
    border-radius: 0;
    &${HorizontalNavMenuItem.Text} {
        font-family: ${props => props.theme.font.main};
        padding: 1rem;
        text-align: center;
    }
    &&${HorizontalNavMenuItem.Link} {
        color: ${props => props.theme.white};
        &.active {
            background-color: ${props => props.theme.primary.main};
        }
        height: 100%;
        width: 100%;

        &:hover {
            background-color: ${props => props.theme.primary.light};
        }
        &:focus {
            background-color: ${props => props.theme.primary.main};
        }
    }

    @media screen and (max-width: calc(${props => props.theme.layout.mobile} - 1px)) {
        font-size: 1em;
    }
` as typeof HorizontalNavMenuItem.Root;

export const CustomLayout = styled(ResponsiveLayout.Root)`
    background-color: ${props => props.theme.neutral.faint};
    &${ResponsiveLayout.Content} {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        padding: 4rem 0;
        margin: 1rem;
    }
` as typeof ResponsiveLayout.Root;

export const LeadingSeparator = styled(Separator.Root)`
    margin-bottom: 1.5rem;
`;
