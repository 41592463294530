import { string, boolean, date } from 'yup';
import { StudentAnswersEnum, StudentAnswersKeys } from './types';
import { generateSchema, SchemaLogicType } from '@papa/components';
import { dropins } from './dropins';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const earliestSupportedYear = currentYear - 81;
const latestSupportedYear = currentYear - 13;
const supportedMonth = currentDate.getMonth();
const supportedDate = currentDate.getDate();

const isValidString = (value: string) => {
    return value && value.length > 0;
};
const transformOptionalBoolean = () => {
    return boolean()
        .transform(value => !!value)
        .optional();
};

const personalInformationSchema = {
    fname: string().required('First name is required.'),
    mname: string().optional(),
    lname: string().required('Last name is required.'),
    pname: string().optional(),
    prev_lname: string().optional(),
    gender: string().required('Gender is required.'),
    gender_identity: string().optional(),
    birth_day: date()
        .typeError('A valid date of birth is required.')
        .required('A valid date of birth is required.')
        .min(
            new Date(earliestSupportedYear, supportedMonth, supportedDate),
            `The minimum supported year is ${earliestSupportedYear}. Please contact help support for assistance.`
        )
        .max(new Date(latestSupportedYear, supportedMonth, supportedDate), `You must be ${currentYear - latestSupportedYear} years or older to apply.`),

    ss_no_left: string()
        .matches(/^$|\d\d\d-\d\d-\d\d\d\d/, 'Please provide a valid social security number.')
        .max(11)
        .optional(),
};

const contactInfoSchema = {
    phone_no_home_area: string()
        .matches(/^$|\(\d\d\d\)-\d\d\d-\d\d\d\d/, 'Please provide a valid US phone number for home phone.')
        .optional(),
    phone_no_cell_area: string()
        .matches(/^$|\(\d\d\d\)-\d\d\d-\d\d\d\d/, 'Please provide a valid US phone number for cell phone.')
        .when('phone_no_home_area', {
            is: (value: string) => !!value,
            then: schema =>
                schema.when('cell_txt_opt_in', {
                    is: true,
                    then: schema => schema.required('A cell phone number is required to opt in to text message updates.'),
                    otherwise: schema => schema.optional(),
                }),
            otherwise: schema => schema.required('Please enter either a home phone or cell phone number.'),
        }),
    cell_txt_opt_in: boolean().optional(),
    address: string().required('Address is required.'),
    apt_num: string().optional(),
    city: string().required('City is required.'),
    state: string().when('country_cd', {
        is: (value: string) => value && ['USA', 'CAN'].includes(value.toUpperCase()),
        then: schema => schema.required('State is required.'),
        otherwise: schema => schema.optional(),
    }),
    zip: string().when('country_cd', {
        is: (value: string) => value && ['USA'].includes(value.toUpperCase()),
        then: schema => schema.required('Zip/Postal Code is required').min(5, 'Zip/Postal Code must be at least 5 characters.'),
        otherwise: schema => schema.optional(),
    }),
    country: string().required('Country is required.'),
    mail_same_as_perm: string().required('Please indicate if your mailing address is the same as your permanent address.'),
    mailing_address: string().optional(),
    mailing_apt_num: string().optional(),
    mailing_city: string().optional(),
    mailing_state: string().optional(),
    mailing_zip: string().optional(),
    mailing_country: string().optional(),
    mail_eff_date_day: date()
        .transform(value => (value === '' || 'Invalid Date' ? null : value))
        .nullable(),
};

const citizenshipSchema = {
    citizen_type: string().optional(),
    birth_country_cd: string().optional(),
    birth_state: string().optional(),
    citizen_country: string().optional(),
    alien_reg_no: string().optional(),
    us_study_radio: string().when('citizen_type', {
        is: (value: string) => value && value === '3',
        then: schema => schema.required('Please indicate if you intend to study in the US.'),
        otherwise: schema => schema.optional(),
    }),
    us_residency_radio: string().optional(),
    citizen_visa_status: string().optional(),
    intended_visa_type: string().optional(),
    current_visa_number: string().optional(),
};

const ethnicitySchema = {
    ethnicity_hispanic: string().optional(),
    race_american_indian: boolean().optional(),
    race_asian: boolean().optional(),
    race_black: boolean().optional(),
    race_native_hawaiian: boolean().optional(),
    race_white: boolean().optional(),
    tribe_radio: string().optional(),
    tribe_affiliation: string().optional(),
    tribe_affiliation_other: string().optional(),
};

const militarySchema = {
    military_status: string().optional(),
    military_branch: string().optional(),
    military_status_specified: string().optional(),
    military_tuition_assistance: string().optional(),
    military_self_edu_benefits: string().optional(),
    military_self_veterans_benefits: string().optional(),
    military_dependent: string().optional(),
    military_spouse_status: string().optional(),
    military_spouse_benefits: string().optional(),
    law_enforcement: string().optional(),
};

const yourPlansSchema = {
    program_type: string().required('Online Program Type is required.'),
    app_major: string().when('program_type', {
        is: (value: string) => !!value === true,
        then: schema => schema.required('Please select a major.'),
        otherwise: schema => schema.optional(),
    }),
    app_concentration: string().optional(),
    ctl_int_coll_univ: transformOptionalBoolean(),
    ctl_int_elm_school: transformOptionalBoolean(),
    ctl_int_ind_priv: transformOptionalBoolean(),
    ctl_int_sec_school: transformOptionalBoolean(),
    ctl_int_sec_comm_coll: transformOptionalBoolean(),
    ce_int_geotechnical: transformOptionalBoolean(),
    ce_int_structural: transformOptionalBoolean(),
    ce_int_transportation: transformOptionalBoolean(),
    ce_int_water_resources: transformOptionalBoolean(),
    ga_int_european_studies: transformOptionalBoolean(),
    ga_int_global_studies: transformOptionalBoolean(),
    ga_int_la_studies: transformOptionalBoolean(),
    ga_int_asian_studies: transformOptionalBoolean(),
    ga_int_me_studies: transformOptionalBoolean(),
    hw_int_water_man: transformOptionalBoolean(),
    hw_int_water_qual: transformOptionalBoolean(),
    hw_int_water_quan: transformOptionalBoolean(),
    li_int_data: transformOptionalBoolean(),
    li_int_info_tech: transformOptionalBoolean(),
    li_int_acad: transformOptionalBoolean(),
    li_int_pub: transformOptionalBoolean(),
    li_int_school: transformOptionalBoolean(),
    li_int_special: transformOptionalBoolean(),
    li_int_arc_pres: transformOptionalBoolean(),
    li_int_youth_serv: transformOptionalBoolean(),
    se_int_appl_ba: transformOptionalBoolean(),
    se_int_fund_se: transformOptionalBoolean(),
    se_int_sec_trans: transformOptionalBoolean(),
    cs_int: string().optional(),
    geog_env_int: string().optional(),
    ngem_int_engineering: transformOptionalBoolean(),
    ngem_int_management: transformOptionalBoolean(),
    enroll_term: string().when('app_major', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Enroll term is required.'),
        otherwise: schema => schema.optional(),
    }),
};

const educationalHxSchema = {
    college_0_transcript: string().optional(),
    identification: string().required('Please upload a form of identification.'),
    parent1_education: string().optional(),
    parent2_education: string().optional(),
    prev_attend: string().required(`Please indicate if you have previously attended ${dropins.partnerName}.`),
    prev_attend_from_month: string().optional(),
    prev_attend_to_month: string().optional(),
    prev_attend_from_year: string().optional(),
    prev_attend_to_year: string().optional(),
    sooner_id: string().optional(),
    college_0_degree_earned: string().optional(),
    college_0_gpa: string().optional(),
    college_0_credits_earned: string().optional(),
    college_0_attended_from_month: string().when('college_0_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Month is required for start date.'),
        otherwise: schema => schema.optional(),
    }),
    college_0_attended_to_month: string().when('college_0_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Month is required for end date.'),
        otherwise: schema => schema.optional(),
    }),
    college_0_attended_from_year: string().when('college_0_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Year is required for start date.'),
        otherwise: schema => schema.optional(),
    }),
    college_0_attended_to_year: string().when('college_0_name', {
        is: (value: string) => isValidString(value),
        then: schema => schema.required('Year is required for end date.'),
        otherwise: schema => schema.optional(),
    }),
    college_1_degree_earned: string().optional(),
    college_1_gpa: string().optional(),
    college_1_credits_earned: string().optional(),
    college_1_attended_from_month: string().optional(),
    college_1_attended_to_month: string().optional(),
    college_1_attended_from_year: string().optional(),
    college_1_attended_to_year: string().optional(),
    college_2_degree_earned: string().optional(),
    college_2_gpa: string().optional(),
    college_2_credits_earned: string().optional(),
    college_2_attended_from_month: string().optional(),
    college_2_attended_to_month: string().optional(),
    college_2_attended_from_year: string().optional(),
    college_2_attended_to_year: string().optional(),
    college_3_degree_earned: string().optional(),
    college_3_gpa: string().optional(),
    college_3_credits_earned: string().optional(),
    college_3_attended_from_month: string().optional(),
    college_3_attended_to_month: string().optional(),
    college_3_attended_from_year: string().optional(),
    college_3_attended_to_year: string().optional(),
    college_4_degree_earned: string().optional(),
    college_4_gpa: string().optional(),
    college_4_credits_earned: string().optional(),
    college_4_attended_from_month: string().optional(),
    college_4_attended_to_month: string().optional(),
    college_4_attended_from_year: string().optional(),
    college_4_attended_to_year: string().optional(),
    college_0_name: string().required('School name is required.'),
    college_1_name: string().optional(),
    college_2_name: string().optional(),
    college_3_name: string().optional(),
    college_4_name: string().optional(),
    college_0_addr1: string().optional(),
    college_1_addr1: string().optional(),
    college_2_addr1: string().optional(),
    college_3_addr1: string().optional(),
    college_4_addr1: string().optional(),
    college_0_city: string().optional(),
    college_1_city: string().optional(),
    college_2_city: string().optional(),
    college_3_city: string().optional(),
    college_4_city: string().optional(),
    college_0_state: string().optional(),
    college_1_state: string().optional(),
    college_2_state: string().optional(),
    college_3_state: string().optional(),
    college_4_state: string().optional(),
    college_0_zip: string().optional(),
    college_1_zip: string().optional(),
    college_2_zip: string().optional(),
    college_3_zip: string().optional(),
    college_4_zip: string().optional(),
    college_0_ceeb: string().optional(),
    college_1_ceeb: string().optional(),
    college_2_ceeb: string().optional(),
    college_3_ceeb: string().optional(),
    college_4_ceeb: string().optional(),
};

const testScoresSchema = {
    gmat_taken: string().when('app_major', {
        is: (value: string) => (value && value === '34') || (value && value === '56'),
        then: schema => schema.required('Please indicate if you have taken the GMAT.'),
        otherwise: schema => schema.optional(),
    }),
    gmat_taken_month: string().optional(),
    gmat_taken_year: string().optional(),
    gmat_score_composite: string().when('gmat_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d\d\d/, 'Please provide a valid GMAT Composite Score. Scores range from 200-800.'),
        otherwise: schema => schema.optional(),
    }),
    gmat_score_composite_percentile: string().when('gmat_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GMAT Composite Percentile.'),
        otherwise: schema => schema.optional(),
    }),
    gmat_score_quant: string().when('gmat_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GMAT Quantitative Score. Scores range from 0-60.'),
        otherwise: schema => schema.optional(),
    }),
    gmat_score_quant_percentile: string().when('gmat_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GMAT Quantitative Percentile.'),
        otherwise: schema => schema.optional(),
    }),
    gmat_score_verbal: string().when('gmat_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GMAT Verbal Score. Scores range from 0-60.'),
        otherwise: schema => schema.optional(),
    }),
    gmat_score_verbal_percentile: string().when('gmat_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GMAT Verbal Percentile.'),
        otherwise: schema => schema.optional(),
    }),
    gmat_score_writing: string().when('gmat_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GMAT Analytical Writing Score. Scores range from 0-6 in half-point increments.'),
        otherwise: schema => schema.optional(),
    }),
    gmat_score_writing_percentile: string().when('gmat_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GMAT Analytical Writing Percentile.'),
        otherwise: schema => schema.optional(),
    }),
    gmat_score_reasoning: string().when('gmat_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GMAT Integrated Reasoning Score. Scores range from 1-8.'),
        otherwise: schema => schema.optional(),
    }),
    gmat_score_reasoning_percentile: string().when('gmat_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GMAT Integrated Reasoning Percentile.'),
        otherwise: schema => schema.optional(),
    }),
    gmat_plan_month: string().optional(),
    gmat_plan_year: string().optional(),
    gre_taken: string().when('app_major', {
        is: (value: string) => (value && value === '34') || (value && value === '56'),
        then: schema => schema.required('Please indicate if you have taken the GRE.'),
        otherwise: schema => schema.optional(),
    }),
    gre_taken_month: string().optional(),
    gre_taken_year: string().optional(),
    gre_score_verbal: string().when('gre_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d\d\d/, 'Please provide a valid GRE Verbal Reasoning Score. Scores range from 130-170.'),
        otherwise: schema => schema.optional(),
    }),
    gre_score_verbal_percentile: string().when('gre_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GRE Verbal Reasoning Percentile.'),
        otherwise: schema => schema.optional(),
    }),
    gre_score_quant: string().when('gre_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d\d\d/, 'Please provide a valid GRE Quantitative Reasoning Score. Scores range from 130-170.'),
        otherwise: schema => schema.optional(),
    }),
    gre_score_quant_percentile: string().when('gre_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GRE Quantitative Reasoning Percentile.'),
        otherwise: schema => schema.optional(),
    }),
    gre_score_writing: string().when('gre_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GRE Analytical Writing Score. Scores range from 0-6 in half-point increments.'),
        otherwise: schema => schema.optional(),
    }),
    gre_score_writing_percentile: string().when('gre_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid GRE Analytical Writing Percentile.'),
        otherwise: schema => schema.optional(),
    }),
    gre_plan_month: string().optional(),
    gre_plan_year: string().optional(),
    toefl_taken: string().optional(),
    toefl_taken_month: string().optional(),
    toefl_taken_year: string().optional(),
    toefl_score: string().when('toefl_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema =>
            schema.when('citizen_type', {
                is: (value: string) => (value && value === '2') || (value && value === '3'),
                then: schema => schema.matches(/\d/, 'Please provide a valid TOEFL Score.'),
                otherwise: schema => schema.optional(),
            }),
    }),
    toefl_score_listening: string().when('toefl_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid TOEFL Listening Score.'),
        otherwise: schema => schema.optional(),
    }),
    toefl_score_speaking: string().when('toefl_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid TOEFL Speaking Score.'),
        otherwise: schema => schema.optional(),
    }),
    toefl_score_reading: string().when('toefl_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid TOEFL Reading Score.'),
        otherwise: schema => schema.optional(),
    }),
    toefl_score_writing: string().when('toefl_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.matches(/\d/, 'Please provide a valid TOEFL Writing Score.'),
        otherwise: schema => schema.optional(),
    }),
    toefl_plan_month: string().optional(),
    toefl_plan_year: string().optional(),
    ielts_taken: string().optional(),
    ielts_taken_month: string().optional(),
    ielts_taken_year: string().optional(),
    ielts_score_overall: string().when('ielts_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema =>
            schema.when('citizen_type', {
                is: (value: string) => (value && value === '2') || (value && value === '3'),
                then: schema => schema.matches(/\d/, 'Please provide a valid IELTS Score.'),
                otherwise: schema => schema.optional(),
            }),
    }),
    ielts_score_listening: string().when('ielts_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema =>
            schema.when('citizen_type', {
                is: (value: string) => (value && value === '2') || (value && value === '3'),
                then: schema => schema.matches(/\d/, 'Please provide a valid IELTS Listening Score.'),
                otherwise: schema => schema.optional(),
            }),
    }),
    ielts_score_reading: string().when('ielts_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema =>
            schema.when('citizen_type', {
                is: (value: string) => (value && value === '2') || (value && value === '3'),
                then: schema => schema.matches(/\d/, 'Please provide a valid IELTS Reading Score.'),
                otherwise: schema => schema.optional(),
            }),
    }),
    ielts_score_writing: string().when('ielts_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema =>
            schema.when('citizen_type', {
                is: (value: string) => (value && value === '2') || (value && value === '3'),
                then: schema => schema.matches(/\d/, 'Please provide a valid IELTS Writing Score.'),
                otherwise: schema => schema.optional(),
            }),
    }),
    ielts_score_speaking: string().when('ielts_taken', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema =>
            schema.when('citizen_type', {
                is: (value: string) => (value && value === '2') || (value && value === '3'),
                then: schema => schema.matches(/\d/, 'Please provide a valid IELTS Speaking Score.'),
                otherwise: schema => schema.optional(),
            }),
    }),
    ielts_plan_month: string().optional(),
    ielts_plan_year: string().optional(),
};

const finalStepsSchema = {
    conviction: string().required('Please indicate if you have ever been convicted of a felony.'),
    conduct_question1_month: string().optional(),
    conduct_question1_year: string().optional(),
    conviction_explain: string().when('conviction', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.required('Please explain the circumstances of the felony conviction.'),
        otherwise: schema => schema.optional(),
    }),
    suspended: string().required('Please indicate if you have ever been suspended or expelled.'),
    conduct_question2_month: string().optional(),
    conduct_question2_year: string().optional(),
    suspended_explain: string().when('suspended', {
        is: (value: string) => value && value.toUpperCase() === 'Y',
        then: schema => schema.required('Please explain the circumstances of your suspension or expulsion.'),
        otherwise: schema => schema.optional(),
    }),
    conduct_question3: string().optional(),
    conduct_question3_month: string().optional(),
    conduct_question3_year: string().optional(),
    conduct_question3_explain: string().optional(),
    conduct_question4: string().optional(),
    conduct_question4_month: string().optional(),
    conduct_question4_year: string().optional(),
    conduct_question4_explain: string().optional(),
    conduct_question5: string().optional(),
    conduct_question5_month: string().optional(),
    conduct_question5_year: string().optional(),
    conduct_question5_explain: string().optional(),
    legal_agree: boolean().required('You must accept these terms to submit your application.').oneOf([true], 'You must accept these terms to submit your application.'),
};

export const schema = generateSchema<SchemaLogicType<StudentAnswersKeys>, typeof StudentAnswersEnum>(
    {
        ...contactInfoSchema,
        ...personalInformationSchema,
        ...citizenshipSchema,
        ...ethnicitySchema,
        ...militarySchema,
        ...yourPlansSchema,
        ...educationalHxSchema,
        ...testScoresSchema,
        ...finalStepsSchema,
    },
    StudentAnswersEnum
);
