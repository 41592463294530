import { ResponsiveLayout, Separator, ThemeColor, ThemeShade, ModalButton } from '@papa/components';
import styled from 'styled-components';
import { dropins } from '../dataModel/dropins';
import { ItalicSubtext, PartnerMailToLink } from '../Pages/Form/FormHelpers';
import { Blurb, Flex } from '../styles/FormElements';

const FooterLayout = styled(ResponsiveLayout.Root)`
    &${ResponsiveLayout.Container} {
        background-color: ${props => props.theme.secondary.main};
        color: ${props => props.theme.white};
        font-family: ${props => props.theme.font.main};
    }

    ${ResponsiveLayout.Content} {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        min-height: 4rem;
    }
`;

const StylishEabert = styled.img`
    object-fit: contain;
    width: 100%;
`;

const FooterSeparator = styled(Separator.Root)`
    min-height: 4rem;
` as typeof Separator.Root;

export const Footer = () => {
    return (
        <footer>
            <FooterLayout>
                <img src="/eab-logo-white.svg" alt="EAB logo" height="64px" width="150px" />
                <LinkAndAddress color={ThemeColor.neutral} shade={ThemeShade.light} />
            </FooterLayout>
        </footer>
    );
};

interface ILinkAndAddressProps {
    color: keyof typeof ThemeColor;
    shade: keyof typeof ThemeShade;
}
export const LinkAndAddress = (props: ILinkAndAddressProps) => {
    const { color, shade } = props;
    return (
        <Flex>
            <div style={{ alignSelf: 'center', textAlign: 'right', padding: '0 0.7rem' }}>
                <Blurb color={color} shade={shade}>
                    {dropins.partnerName}
                </Blurb>
                <Blurb color={color} shade={shade}>
                    {dropins.partnerAddress1}
                </Blurb>
                <Blurb color={color} shade={shade}>
                    {dropins.partnerAddress2}
                </Blurb>
            </div>
            <FooterSeparator orientation={Separator.Orientations.vertical} color={ThemeColor.error} shade={ThemeShade.light} weight="thin" />
            <div style={{ alignSelf: 'center', display: 'block' }}>
                <StyledHelpLink color={color} shade={shade} />
                <StyledPrivacyButton color={color} shade={shade} />
            </div>
        </Flex>
    );
};

interface IStyledModalProps {
    className?: string;
}
const HelpLink = (props: IStyledModalProps) => {
    return (
        <ModalButton.Root
            leadingIcon="help"
            buttonText="Technical Help"
            modalHeader="Technical Help"
            size="xs"
            className={props.className}
            modalContent={
                <>
                    <Blurb color={ThemeColor.secondary} shade={ThemeShade.main}>
                        Having issues? Give us a shout! {PartnerMailToLink}
                    </Blurb>
                    <StylishEabert alt="Eabert chilling with pizza on the golf course" src="/chill-eabert.png" />
                    <br />
                    <br />
                    <ItalicSubtext>
                        The above email address is not monitored. If you actually need help, please reach out to the appropriate product owner or open a ticket.
                    </ItalicSubtext>
                </>
            }
        />
    );
};

const PrivacyLink = (props: IStyledModalProps) => {
    return (
        <ModalButton.Root
            leadingIcon="lock"
            buttonText="Privacy"
            modalHeader="Privacy"
            size="m"
            className={props.className}
            modalContent={
                <>
                    {dropins.privacyModal1}
                    <br />
                    <br />
                    {dropins.privacyModal2}
                    You may{' '}
                    <a href="https://accounts.google.com/InteractiveLogin/signinchooser?continue=https%3A%2F%2Fadssettings.google.com%2Fu%2F0%2Fauthenticated&followup=https%3A%2F%2Fadssettings.google.com%2Fu%2F0%2Fauthenticated&passive=1209600&ifkv=AQMjQ7RQjImF3M86zHMbbQmy2CS6WvYkQFUCAdXOQhlI2L4K22apTteldbqp1ZmDosrr4QvJp_Rgdw&flowName=GlifWebSignIn&flowEntry=ServiceLogin">
                        opt out of Google's use of cookies by visiting Google's Ads Settings
                    </a>{' '}
                    and <a href="https://optout.networkadvertising.org/?c=1">opt out of the additional use of cookies by visiting this site</a>.
                    <br />
                    <br />
                    {dropins.privacyModal3}
                    <br />
                    <br />
                    {dropins.privacyModal4}
                    {dropins.partnerName}.
                    <br />
                    <br />
                    <i>{dropins.copyright}</i>
                </>
            }
        />
    );
};

export const StyledHelpLink = styled(HelpLink)<ILinkAndAddressProps>`
    &${ModalButton.Trigger} {
        display: flex;
        align-items: center;
        color: ${props => props.theme[props.color][props.shade]};
        font-family: ${props => props.theme.font.main};
        gap: 0.2rem;
    }
`;

export const StyledPrivacyButton = styled(PrivacyLink)<ILinkAndAddressProps>`
    &${ModalButton.Trigger} {
        display: flex;
        align-items: center;
        color: ${props => props.theme[props.color][props.shade]};
        font-family: ${props => props.theme.font.main};
        gap: 0.2rem;
    }
`;
