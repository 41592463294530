import { Conditional, StudentAnswersDropin, ConditionOperators, MaterialIcon } from '@papa/components';
import { conditionIsLoggedIn } from './WelcomeBanner';
import styled from 'styled-components';
import { StudentAnswersEnum } from '../dataModel/types';
import { Flex } from '../styles/FormElements';

export const FinalStepsEncourage = () => {
    return (
        <Conditional.ClientSnapshot
            conditionObj={conditionIsLoggedIn}
            renderProps={{
                truthyChild: <EncourageContent />,
            }}
        />
    );
};

const Container = styled.div`
    background-color: ${props => props.theme.primary.light}90;
    border-radius: ${props => props.theme.borderRadius};
    color: ${props => props.theme.secondary.main};
    font-family: ${props => props.theme.font.main};
    padding: 2rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const EncourageHeader = styled.h2`
    font-size: 1.2em;
    margin: 0.5rem;
    letter-spacing: 0.4rem;
    font-weight: 900;
    color: ${props => props.theme.secondary.dark};
    font-family: ${props => props.theme.font.display};
    text-transform: uppercase;
`;

const EncourageContent = () => {
    return (
        <Container>
            <Flex>
                <MaterialIcon.Root iconName="celebration" />
                <EncourageHeader>
                    You're almost there
                    <FirstNameDropin />!
                </EncourageHeader>
            </Flex>
            <p>Once you click "Submit Application," you'll see your final steps for completing our application. </p>
        </Container>
    );
};

export const FirstNameDropin = () => {
    return (
        <Conditional.StudentAnswers
            conditionObj={{
                valueKey: StudentAnswersEnum.fname,
                operator: ConditionOperators.answered,
            }}
            renderProps={{
                truthyChild: (
                    <>
                        , <StudentAnswersDropin.Root yupValueKey={StudentAnswersEnum.fname} />
                    </>
                ),
            }}
        />
    );
};
