import { NavButton, SaveButton, SubmitButton, Form, MaterialIcon, ButtonWithIcon, ThemeColor, ThemeShade } from '@papa/components';
import { QueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { StudentAnswersKeys } from '../../dataModel/types';
import { schema } from '../../dataModel/yupSchema';
import {
    CustomLayout,
    ErrorStyledIcon,
    ErrorStyledSectionHeader,
    Flex,
    StyledHorizontalNav,
    StyledHorizontalNavMenuItem,
    StyledSeparator,
    StyledValidationErrorSummary,
} from '../../styles/FormElements';
import { Footer } from '../../Components/Footer';
import { WelcomeBanner } from '../../Components/WelcomeBanner';
import { loadAboutYou, loadEducation, loadFinalSteps } from '../../App';
import { ErrorItalicSubtext } from './FormHelpers';

const StyledActionButton = (Button: (props: any) => JSX.Element) => styled(Button)`
    font-size: 1.1em;
    background-color: ${props => props.theme.primary.main};
    display: flex;
    align-items: center;
    color: ${props => props.theme.neutral.faint};
    box-shadow: none;

    &:hover,
    &:focus {
        background-color: ${props => props.theme.primary.light};
    }

    &[disabled],
    &[disabled] &:hover,
    &[disabled] &:focus {
        background-color: ${props => props.theme.disabled.main};
    }

    &${ButtonWithIcon.LoadingContainer} {
        background-color: transparent;
        margin-left: 0.4rem;
    }

    &${MaterialIcon.Root} {
        background-color: transparent;
    }
`;

export const StyledSave = StyledActionButton(SaveButton.Root);
export const StyledSubmit = StyledActionButton(SubmitButton.Root);

export const StyledPrevious = styled(NavButton.Root)`
    box-shadow: none;
    font-size: 1.1em;
    background-color: ${props => props.theme.neutral.faint};
    display: flex;
    align-items: center;
    color: ${props => props.theme.primary.main};
    border: 3px solid ${props => props.theme.primary.main};

    &:hover,
    &:focus {
        background-color: ${props => props.theme.neutral.faint};
        border: 3px solid ${props => props.theme.primary.light};
        color: ${props => props.theme.primary.light};
    }

    &${MaterialIcon.Root} {
        background-color: ${props => props.theme.neutral.faint};
        border: unset;
    }
`;

export const StyledBottomNav = styled.nav`
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    width: 100%;
`;

interface IFormLayoutProps {
    children: JSX.Element | JSX.Element[];
    queryClient: QueryClient;
}
export const FormLayout = (props: IFormLayoutProps) => {
    const { children, queryClient } = props;
    return (
        <>
            <WelcomeBanner queryClient={queryClient} hasWelcomeMessage />
            <StyledHorizontalNav>
                <StyledHorizontalNavMenuItem leadingIcon="face" route="/about" loader={loadAboutYou}>
                    About You
                </StyledHorizontalNavMenuItem>
                <StyledHorizontalNavMenuItem leadingIcon="auto_stories" route="/education" loader={loadEducation}>
                    Education
                </StyledHorizontalNavMenuItem>
                <StyledHorizontalNavMenuItem leadingIcon="done" route="/final-steps" loader={loadFinalSteps}>
                    Final Steps
                </StyledHorizontalNavMenuItem>
            </StyledHorizontalNav>
            <Form.Root<StudentAnswersKeys> schema={schema}>
                <CustomLayout>
                    <>
                        <StyledValidationErrorSummary
                            label={
                                <>
                                    <Flex>
                                        <ErrorStyledIcon iconName="warning" />
                                        <ErrorStyledSectionHeader>We're missing some information!</ErrorStyledSectionHeader>
                                    </Flex>
                                    <ErrorItalicSubtext>
                                        The links below show what needs to be corrected. Missing or incorrect items will be highlighted on each page; simply fix them and
                                        then resubmit your application.
                                    </ErrorItalicSubtext>
                                    <StyledSeparator orientation="horizontal" weight="regular" color={ThemeColor.error} shade={ThemeShade.dark} />
                                </>
                            }
                            schema={schema}
                        />
                        {children}
                    </>
                </CustomLayout>
            </Form.Root>
            <Footer />
        </>
    );
};
