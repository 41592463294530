import { QueryClient } from '@tanstack/react-query';

export enum StudentAnswersEnum {
    fname = 'fname',
    mname = 'mname',
    lname = 'lname',
    pname = 'pname',
    prev_lname = 'prev_lname',
    gender = 'gender',
    gender_identity = 'gender_identity',
    birth_day = 'birth_day',
    ss_no_left = 'ss_no_left',
    phone_no_home_area = 'phone_no_home_area',
    phone_no_cell_area = 'phone_no_cell_area',
    cell_txt_opt_in = 'cell_txt_opt_in',
    address = 'address',
    apt_num = 'apt_num',
    city = 'city',
    state = 'state',
    zip = 'zip',
    country = 'country',
    mail_same_as_perm = 'mail_same_as_perm',
    mailing_address = 'mailing_address',
    mailing_apt_num = 'mailing_apt_num',
    mailing_city = 'mailing_city',
    mailing_state = 'mailing_state',
    mailing_zip = 'mailing_zip',
    mailing_country = 'mailing_country',
    mail_eff_date_day = 'mail_eff_date_day',
    citizen_type = 'citizen_type',
    birth_country_cd = 'birth_country_cd',
    birth_state = 'birth_state',
    citizen_country = 'citizen_country',
    alien_reg_no = 'alien_reg_no',
    us_study_radio = 'us_study_radio',
    us_residency_radio = 'us_residency_radio',
    citizen_visa_status = 'citizen_visa_status',
    intended_visa_type = 'intended_visa_type',
    current_visa_number = 'current_visa_number',
    ethnicity_hispanic = 'ethnicity_hispanic',
    race_american_indian = 'race_american_indian',
    race_asian = 'race_asian',
    race_black = 'race_black',
    race_native_hawaiian = 'race_native_hawaiian',
    race_white = 'race_white',
    tribe_radio = 'tribe_radio',
    tribe_affiliation = 'tribe_affiliation',
    tribe_affiliation_other = 'tribe_affiliation_other',
    military_status = 'military_status',
    military_branch = 'military_branch',
    military_status_specified = 'military_status_specified',
    military_tuition_assistance = 'military_tuition_assistance',
    military_self_edu_benefits = 'military_self_edu_benefits',
    military_self_veterans_benefits = 'military_self_veterans_benefits',
    military_dependent = 'military_dependent',
    military_spouse_status = 'military_spouse_status',
    military_spouse_benefits = 'military_spouse_benefits',
    law_enforcement = 'law_enforcement',
    program_type = 'program_type',
    app_major = 'app_major',
    app_concentration = 'app_concentration',
    ctl_int_coll_univ = 'ctl_int_coll_univ',
    ctl_int_elm_school = 'ctl_int_elm_school',
    ctl_int_ind_priv = 'ctl_int_ind_priv',
    ctl_int_sec_school = 'ctl_int_sec_school',
    ctl_int_sec_comm_coll = 'ctl_int_sec_comm_coll',
    ce_int_geotechnical = 'ce_int_geotechnical',
    ce_int_structural = 'ce_int_structural',
    ce_int_transportation = 'ce_int_transportation',
    ce_int_water_resources = 'ce_int_water_resources',
    ga_int_european_studies = 'ga_int_european_studies',
    ga_int_global_studies = 'ga_int_global_studies',
    ga_int_la_studies = 'ga_int_la_studies',
    ga_int_asian_studies = 'ga_int_asian_studies',
    ga_int_me_studies = 'ga_int_me_studies',
    hw_int_water_man = 'hw_int_water_man',
    hw_int_water_qual = 'hw_int_water_qual',
    hw_int_water_quan = 'hw_int_water_quan',
    li_int_data = 'li_int_data',
    li_int_info_tech = 'li_int_info_tech',
    li_int_acad = 'li_int_acad',
    li_int_pub = 'li_int_pub',
    li_int_school = 'li_int_school',
    li_int_special = 'li_int_special',
    li_int_arc_pres = 'li_int_arc_pres',
    li_int_youth_serv = 'li_int_youth_serv',
    se_int_appl_ba = 'se_int_appl_ba',
    se_int_fund_se = 'se_int_fund_se',
    se_int_sec_trans = 'se_int_sec_trans',
    cs_int = 'cs_int',
    geog_env_int = 'geog_env_int',
    ngem_int_engineering = 'ngem_int_engineering',
    ngem_int_management = 'ngem_int_management',
    enroll_term = 'enroll_term',
    parent1_education = 'parent1_education',
    parent2_education = 'parent2_education',
    prev_attend = 'prev_attend',
    prev_attend_from_month = 'prev_attend_from_month',
    prev_attend_to_month = 'prev_attend_to_month',
    prev_attend_from_year = 'prev_attend_from_year',
    prev_attend_to_year = 'prev_attend_to_year',
    sooner_id = 'sooner_id',
    college_0_degree_earned = 'college_0_degree_earned',
    college_0_gpa = 'college_0_gpa',
    college_0_credits_earned = 'college_0_credits_earned',
    college_0_attended_from_month = 'college_0_attended_from_month',
    college_0_attended_to_month = 'college_0_attended_to_month',
    college_0_attended_from_year = 'college_0_attended_from_year',
    college_0_attended_to_year = 'college_0_attended_to_year',
    college_1_degree_earned = 'college_1_degree_earned',
    college_1_gpa = 'college_1_gpa',
    college_1_credits_earned = 'college_1_credits_earned',
    college_1_attended_from_month = 'college_1_attended_from_month',
    college_1_attended_to_month = 'college_1_attended_to_month',
    college_1_attended_from_year = 'college_1_attended_from_year',
    college_1_attended_to_year = 'college_1_attended_to_year',
    college_2_degree_earned = 'college_2_degree_earned',
    college_2_gpa = 'college_2_gpa',
    college_2_credits_earned = 'college_2_credits_earned',
    college_2_attended_from_month = 'college_2_attended_from_month',
    college_2_attended_to_month = 'college_2_attended_to_month',
    college_2_attended_from_year = 'college_2_attended_from_year',
    college_2_attended_to_year = 'college_2_attended_to_year',
    college_3_degree_earned = 'college_3_degree_earned',
    college_3_gpa = 'college_3_gpa',
    college_3_credits_earned = 'college_3_credits_earned',
    college_3_attended_from_month = 'college_3_attended_from_month',
    college_3_attended_to_month = 'college_3_attended_to_month',
    college_3_attended_from_year = 'college_3_attended_from_year',
    college_3_attended_to_year = 'college_3_attended_to_year',
    college_4_degree_earned = 'college_4_degree_earned',
    college_4_gpa = 'college_4_gpa',
    college_4_credits_earned = 'college_4_credits_earned',
    college_4_attended_from_month = 'college_4_attended_from_month',
    college_4_attended_to_month = 'college_4_attended_to_month',
    college_4_attended_from_year = 'college_4_attended_from_year',
    college_4_attended_to_year = 'college_4_attended_to_year',
    college_0_name = 'college_0_name',
    college_1_name = 'college_1_name',
    college_2_name = 'college_2_name',
    college_3_name = 'college_3_name',
    college_4_name = 'college_4_name',
    college_0_addr1 = 'college_0_addr1',
    college_1_addr1 = 'college_1_addr1',
    college_2_addr1 = 'college_2_addr1',
    college_3_addr1 = 'college_3_addr1',
    college_4_addr1 = 'college_4_addr1',
    college_0_city = 'college_0_city',
    college_1_city = 'college_1_city',
    college_2_city = 'college_2_city',
    college_3_city = 'college_3_city',
    college_4_city = 'college_4_city',
    college_0_state = 'college_0_state',
    college_1_state = 'college_1_state',
    college_2_state = 'college_2_state',
    college_3_state = 'college_3_state',
    college_4_state = 'college_4_state',
    college_0_zip = 'college_0_zip',
    college_1_zip = 'college_1_zip',
    college_2_zip = 'college_2_zip',
    college_3_zip = 'college_3_zip',
    college_4_zip = 'college_4_zip',
    college_0_ceeb = 'college_0_ceeb',
    college_1_ceeb = 'college_1_ceeb',
    college_2_ceeb = 'college_2_ceeb',
    college_3_ceeb = 'college_3_ceeb',
    college_4_ceeb = 'college_4_ceeb',
    gmat_taken = 'gmat_taken',
    gmat_taken_month = 'gmat_taken_month',
    gmat_taken_year = 'gmat_taken_year',
    gmat_score_composite = 'gmat_score_composite',
    gmat_score_composite_percentile = 'gmat_score_composite_percentile',
    gmat_score_quant = 'gmat_score_quant',
    gmat_score_quant_percentile = 'gmat_score_quant_percentile',
    gmat_score_verbal = 'gmat_score_verbal',
    gmat_score_verbal_percentile = 'gmat_score_verbal_percentile',
    gmat_score_writing = 'gmat_score_writing',
    gmat_score_writing_percentile = 'gmat_score_writing_percentile',
    gmat_score_reasoning = 'gmat_score_reasoning',
    gmat_score_reasoning_percentile = 'gmat_score_reasoning_percentile',
    gmat_plan_month = 'gmat_plan_month',
    gmat_plan_year = 'gmat_plan_year',
    gre_taken = 'gre_taken',
    gre_taken_month = 'gre_taken_month',
    gre_taken_year = 'gre_taken_year',
    gre_score_verbal = 'gre_score_verbal',
    gre_score_verbal_percentile = 'gre_score_verbal_percentile',
    gre_score_quant = 'gre_score_quant',
    gre_score_quant_percentile = 'gre_score_quant_percentile',
    gre_score_writing = 'gre_score_writing',
    gre_score_writing_percentile = 'gre_score_writing_percentile',
    gre_plan_month = 'gre_plan_month',
    gre_plan_year = 'gre_plan_year',
    toefl_taken = 'toefl_taken',
    toefl_taken_month = 'toefl_taken_month',
    toefl_taken_year = 'toefl_taken_year',
    toefl_score = 'toefl_score',
    toefl_score_listening = 'toefl_score_listening',
    toefl_score_speaking = 'toefl_score_speaking',
    toefl_score_reading = 'toefl_score_reading',
    toefl_score_writing = 'toefl_score_writing',
    toefl_plan_month = 'toefl_plan_month',
    toefl_plan_year = 'toefl_plan_year',
    ielts_taken = 'ielts_taken',
    ielts_taken_month = 'ielts_taken_month',
    ielts_taken_year = 'ielts_taken_year',
    ielts_score_overall = 'ielts_score_overall',
    ielts_score_listening = 'ielts_score_listening',
    ielts_score_reading = 'ielts_score_reading',
    ielts_score_writing = 'ielts_score_writing',
    ielts_score_speaking = 'ielts_score_speaking',
    ielts_plan_month = 'ielts_plan_month',
    ielts_plan_year = 'ielts_plan_year',
    college_0_transcript = 'college_0_transcript',
    identification = 'identification',
    conviction = 'conviction',
    conduct_question1_month = 'conduct_question1_month',
    conduct_question1_year = 'conduct_question1_year',
    conviction_explain = 'conviction_explain',
    suspended = 'suspended',
    conduct_question2_month = 'conduct_question2_month',
    conduct_question2_year = 'conduct_question2_year',
    suspended_explain = 'suspended_explain',
    conduct_question3 = 'conduct_question3',
    conduct_question3_month = 'conduct_question3_month',
    conduct_question3_year = 'conduct_question3_year',
    conduct_question3_explain = 'conduct_question3_explain',
    conduct_question4 = 'conduct_question4',
    conduct_question4_month = 'conduct_question4_month',
    conduct_question4_year = 'conduct_question4_year',
    conduct_question4_explain = 'conduct_question4_explain',
    conduct_question5 = 'conduct_question5',
    conduct_question5_month = 'conduct_question5_month',
    conduct_question5_year = 'conduct_question5_year',
    conduct_question5_explain = 'conduct_question5_explain',
    legal_agree = 'legal_agree',
}

export type StudentAnswersKeys = keyof typeof StudentAnswersEnum;

export interface IQueryClientOnlyProps {
    queryClient: QueryClient;
}
