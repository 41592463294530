export const dropins = {
    partnerName: 'Eabert University',
    applicationTitle: 'Graduate Application',
    privacyModal1:
        'This Web site uses a "cookie," a small text file that is sent to your computer when you first visit. The cookie is stored on your computer and allows you to be recognized when you visit again later. We use cookies to enhance your visits, most often by prefilling your name and other information on forms you submit to us.',
    privacyModal2:
        "This Web site uses remarketing services, such as Google, Facebook and Adroll, to advertise on third-party Web sites to previous visitors of our site. Third-party vendors place cookies on users' browsers for targeted advertising purposes and serve ads to locations such as the Google search results page or any site in the Google Display Network based on someone's past visits to the Web site. Data such as identification of your internet browser or computer operating system may be collected for targeted advertising. This information is collected to track usage behavior and compile aggregate data to facilitate content improvements. ",
    privacyModal3:
        "Google Drive: When you visit certain EAB Web sites, we may allow users with Google Drive accounts to attach documents from their Google Drive Account to a submitted college enrollment application. The EAB Web site receives documents selected from a user's Google Drive account during the application process. Such documents are stored temporarily and included in the user's complete, delivered application.",
    privacyModal4: 'You can view the complete privacy policy for ',
    copyright: '© 2023 EAB Global, Inc. Patent Pending. All Rights Reserved.',
    partnerAddress1: '11011 W Broad St',
    partnerAddress2: 'Glen Allen, VA 23060',
    partnerEmail: 'MascotExcellence@eabert.com',
    characterLimit: 7_000,
};
